'use client' // Error components must be Client Components

import { Button } from '@/lib/mui'
import { ArrowOutward } from '@/lib/mui'
import { Alert } from '@mui/material'

//Catch server-side render (SSR) errors
export default function Error({
  error,
  reset,
}: {
  error: Error
  reset: () => void
}) {
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <Alert severity="error" variant="outlined">
          Apologies, but it seems that there is a temporary issue accessing this
          page. <br /> Please attempt to refresh the page and try again later.
          We appreciate your patience!
        </Alert>
        <br />
        <Button
          color="primary"
          onClick={
            // Attempt to recover by trying to re-render the segment
            () => reset()
          }
          endIcon={<ArrowOutward />}
        >
          Try Again
        </Button>
        <Button
          className="ml-6"
          color="secondary"
          href="/"
          endIcon={<ArrowOutward />}
        >
          Home
        </Button>
      </div>
    </div>
  )
}
